.grid-container-global {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.2fr 2.6fr 0.2fr;
  grid-template-rows: 0.2fr 0.3fr 3.2fr;
  gap: 1px 1px;
  grid-template-areas: ". . ." ". lbl_cabecera ." ". lbl_contenido .";
}

.lbl_contenido { grid-area: lbl_contenido; }

.lbl_cabecera {
  display: grid;
  grid-template-columns: 0.3fr 1.7fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "lbl_logo lbl_datos";
  grid-area: lbl_cabecera;
}

.lbl_logo { grid-area: lbl_logo; }

.lbl_datos { grid-area: lbl_datos; }
