.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formulario{
    border:solid 2px #708090;
    border-radius: 15px;
    box-shadow: 1px 1px 10px #ABCABC;
    backgroud:#dcdcdc;
    /*margin:0 auto;*/
    width :400px;
    padding:14px;
    display: table;
}
.formulario label{
    display:block;
    font-weigth:bold;
    text-aligin:right;
    width:140px;
    float:left;
    font-size:11px;
}
.formulario select {
    float:left;
    font-size:12px;
    padding:4px 2px;
    border:salid 1px #708090;
    width:200px;
    margin:2px 0 10px 10px;

}

.formulario button {
    float:left;
    font-size:12px;
    padding:4px 2px;
    border:salid 1px #708090;
    width:200px;
    margin:2px 0 10px 10px;

}
.formulario input {
    float:left;
    font-size:12px;
    padding:4px 2px;
    /*border:salid 1px #708090;*/
    width:200px;
    margin:2px 0 10px 10px;

}
.formulario textarea {
    float:left;
    font-size:12px;
    padding:4px 2px;
    /*border:salid 1px #708090;*/
    width:400px;
    height:40px;
    margin:2px 0 10px 10px;

}

table.table_v2 {
  border: 3px solid black;
  background-color: #EEEEEE;
  border-collapse: collapse;
}
table.table_v2 td, table.table_v2 th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table.table_v2 tbody td {
  font-size: 13px;
}
table.table_v2 tr:nth-child(even) {
  background: #ffffff;
}
table.table_v2 thead {
  background: #62676b;
  /*background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  //background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  //background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);*/
  border-bottom: 2px solid #444444;
}
table.table_v2 thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}
table.table_v2 thead th:first-child {
  border-left: none;
}

table.table_v2 tfoot td {
  font-size: 14px;
}
table.table_v2 tfoot .links {
  text-align: right;
}
table.table_v2 tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}
.temporal {
  font-family: Tahoma, Geneva, sans-serif;
  font-size: 23px;
  letter-spacing: -0.8px;
  word-spacing: 2px;
  color: #000000;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}
