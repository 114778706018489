.grid-container-login-docente {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.9fr 1.2fr 0.9fr;
  grid-template-rows: 0.8fr 1.2fr 1fr;
  gap: 1px 1px;
  grid-template-areas: ". . ." ". lbl_login ." ". . .";
}

.lbl_login { grid-area: lbl_login; }
