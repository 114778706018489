html{
  min-height: 100% !important;
  height: 100%;
}


body {
  margin: 0;
  font-family: 'Segoe UI Historic', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100% !important;
  height: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  height: 100%;
  width: 100%;
}
.App{
  height: 100%;
  width: 100%;
}
