.grid-container-seleccion {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.3fr 0.2fr 1.4fr 1.1fr;
  grid-template-rows: 0.3fr 0.9fr 1fr;
  gap: 1px 1px;
  grid-template-areas: ". lbl_etiqueta_seleccion lbl_etiqueta_seleccion lbl_etiqueta_seleccion ." ". lbl_opcion_1 . lbl_opcion_2 ." ". . . . .";
}

.lbl_etiqueta_seleccion { grid-area: lbl_etiqueta_seleccion; }

.lbl_opcion_1 {
  grid-area: lbl_opcion_1;
  border:solid 2px #708090;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #ABCABC;
  backgroud:#dcdcdc;
}

.lbl_opcion_2 {
  grid-area: lbl_opcion_2;
  border:solid 2px #708090;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #ABCABC;
  backgroud:#dcdcdc;
}
